// src/components/Desktop.tsx
import React, { useEffect, useState } from "react";
import { HeaderLogo } from "./HeaderLogo";
import silhouettes from "../assets/silhouettes.svg";
import silhouettesLight from "../assets/silhouettes-light.svg";

export const Desktop = (): JSX.Element => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const updateDarkMode = () => {
      setIsDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
    };

    // Initial prüfen
    updateDarkMode();

    // Event Listener hinzufügen, um auf Änderungen zu reagieren
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", updateDarkMode);

    // Cleanup Event Listener bei Unmount
    return () => {
      mediaQuery.removeEventListener("change", updateDarkMode);
    };
  }, []);

  return (
    <div className="flex items-start justify-center min-h-screen pt-2 tablet:pt-4 bg-[color:var(--semantic-bg-bg-default)]">
      {/* Full Card */}
      <div className="flex flex-col w-[90%] tablet:w-[80%] desktop:w-[1200px] h-auto items-center justify-between bg-[color:var(--semantic-bg-bg-default)] rounded-[16px] overflow-hidden border border-solid border-[color:var(--semantic-card-card-stroke)] shadow-[4px_4px_0px_#bbbbbc] mt-10 mb-20 min-h-[70vh] tablet:min-h-[60vh] lg:min-h-0">
        
        {/* HeaderLogo mit Abstand zum oberen Rand */}
        <div className="flex flex-col items-center justify-center w-full pt-6">
          <HeaderLogo />
        </div>

        {/* Textabschnitt mit Whitespace oben und unten */}
        <div className="flex flex-col w-[85%] tablet:w-[90%] desktop:w-[699px] items-center gap-6 py-8 tablet:py-6">
          <p className="relative self-stretch mt-2 font-bold text-[color:var(--semantic-text-text-default)] text-xl tablet:text-3xl desktop:text-5xl text-center leading-8 desktop:leading-[64px]">
            <span className="text-[color:var(--semantic-text-text-default)]">Bald kannst du hier deinen nächsten </span>
            <span className="text-[color:var(--semantic-text-text-special)]">Komparsenjob</span>
            <span className="text-[color:var(--semantic-text-text-default)]"> ganz einfach finden!</span>
          </p>

          <p className="w-full font-medium text-center leading-6 tablet:leading-8 text-[color:var(--semantic-text-text-default)] text-base tablet:text-lg desktop:text-2xl">
            Ubercrowd revolutioniert die Suche nach Komparsenjobs. Bleib gespannt, die Plattform startet bald!
          </p>
        </div>

        {/* Silhouetten Image */}
        <img
          className="w-full h-auto mt-4"
          alt="Silhouettes"
          src={isDarkMode ? silhouettesLight : silhouettes}
        />
      </div>
    </div>
  );
};
