// src/components/HeaderLogo.tsx
import React, { useEffect, useState } from "react";
import logoIcon from "../assets/logo-icon.svg";
import logoIconLight from "../assets/logo-icon-light.svg";

export const HeaderLogo = (): JSX.Element => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const updateDarkMode = () => {
      setIsDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
    };

    // Initial prüfen
    updateDarkMode();

    // Event Listener hinzufügen, um auf Änderungen zu reagieren
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", updateDarkMode);

    // Cleanup Event Listener bei Unmount
    return () => {
      mediaQuery.removeEventListener("change", updateDarkMode);
    };
  }, []);

  return (
    <div className="inline-flex items-center gap-3 relative">
      <img
        className="relative w-12 h-12"
        alt="Logo icon"
        src={isDarkMode ? logoIconLight : logoIcon}
      />

      <div className="relative w-fit [font-family:'Poppins-Bold',Helvetica] font-bold text-[color:var(--semantic-text-text-default)] text-2xl tracking-[0] leading-[normal]">
        Ubercrowd
      </div>
    </div>
  );
};
